import React from "react";
import Select from "react-select";

import "./Input.css";

const SelectInput = (props) => {
  const { setCategoryHandler, categoryList, value, optionName, name } = props;


  const options = [
    { value: "all", label: `All ${optionName}` },
    ...categoryList.map((category) => ({
      value: category,
      label: category,
    })),
  ];

  

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      fontSize: '14px', 
    }),
  };

  return (
    <div className="selectClass">
      <Select
        value={options.find((option) => option.value === value) ?? ""}
        options={options}
        placeholder={`Select or Search ${optionName}`}
        onChange={(selectedOption) =>
          setCategoryHandler({
            target: { name: name, value: selectedOption?.value },
          })
        }
        isSearchable
        styles={customStyles}
      />
    </div>
  );
};

export default SelectInput;
