import React from "react";
import { Routes, Route } from "react-router-dom";

import { path } from "./Routes.js";

import CustomerCredit from "../pages/customerCredit/CustomerCredit.js";
import InvoiceReport from "../pages/invoiceReport/InvoiceReport.js";
import CustomerDebit from "../pages/customerDebit/CustomerDebit.js";
import ProductDetail from "../pages/productDetail/ProductDetail";
import Outstanding from "../pages/outstanding/Outstanding";
import Comingsoon from "../pages/comingSoon/comingsoon";
import OrderHistory from "../pages/order/OrderHistory";
import OrderSuccess from "../pages/order/OrderSuccess";
import CreateOrder from "../pages/order/CreateOrder";
import NotFound from "../pages/notfound/NotFound.js";
import Profile from "../pages/profile/Profile.js";
import Payment from "../pages/payment/Payment.js";
import StoreList from "../pages/store/StoreList";
import Layout from "../pages/layout/Layout";
import Ledger from "../pages/Ledger/Ledger";
import Login from "../pages/Login/Login";
import Info from "../pages/Info/Info";

const RouteHandler = () => {
  return (
    <Routes>
      <Route path={path.login} element={<Login />} />
      <Route path={path.storeList} element={<StoreList />} />
      <Route
        path={path.productDetail}
        element={
          <Layout>
            <ProductDetail />{" "}
          </Layout>
        }
      />
      <Route
        path={path.creditList}
        element={
          <Layout>
            <CustomerCredit />{" "}
          </Layout>
        }
      />
      <Route
        path={path.debitList}
        element={
          <Layout>
            <CustomerDebit />{" "}
          </Layout>
        }
      />
      <Route
        path={path.profileCustomer}
        element={
          <Layout>
            <Profile />{" "}
          </Layout>
        }
      />
      <Route
        path={path.createOrder}
        element={
          <Layout>
            <CreateOrder />{" "}
          </Layout>
        }
      />
      <Route
        path={path.orderHistory}
        element={
          <Layout>
            <OrderHistory />{" "}
          </Layout>
        }
      />
      <Route
        path={path.orderSucess}
        element={
          <Layout>
            <OrderSuccess />{" "}
          </Layout>
        }
      />
      <Route
        path={path.companyDetails}
        element={
          <Layout>
            <Info />{" "}
          </Layout>
        }
      />
      <Route
        path={path.ledger}
        element={
          <Layout>
            <Ledger />{" "}
          </Layout>
        }
      />
      <Route
        path={path.outstanding}
        element={
          <Layout>
            <Outstanding />{" "}
          </Layout>
        }
      />
      <Route
        path={path.inProgress}
        element={
          <Layout>
            <Comingsoon />{" "}
          </Layout>
        }
      />
      <Route
        path={path.payment}
        element={
          <Layout>
            <Payment />{" "}
          </Layout>
        }
      />
      <Route
        path={path.invoiceReport}
        element={
          <Layout>
            <InvoiceReport />
          </Layout>
        }
      />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default RouteHandler;
