import React from "react";
import classes from "./ProductDetail.module.css";
import styles from "../order/Order.module.css";
import noImg from "../../Assessts/Images/noImg.png";

const ProductDetailPopup = ({
  productModalRef,
  selectedProduct,
  biilingRate,
  openOrderlistPopup,
}) => {
  return (
    <div
      class="modal fade"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
      ref={productModalRef}
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="staticBackdropLabel">
              {selectedProduct?.ERPProductName || "Product Details"}
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className={`${classes.ProductDetailModal} modal-body`}>
            <div className={`${classes.ProductImageContainer}`}>
              <img src={selectedProduct?.Images?.[0] || noImg} alt="img" />
            </div>
            <p>
              <span>Brand Name</span>
              <span>:</span>
              <span>{selectedProduct?.ERPBrand || ""}</span>
            </p>
            <p>
              <span>Category</span>
              <span>:</span>
              <span>{selectedProduct?.ERPCategory || ""}</span>
            </p>
            <p>
              <span>HSN Code</span>
              <span>:</span>
              <span>{selectedProduct?.HSNCode || ""}</span>
            </p>
            <p>
              <span>Group Name</span>
              <span>:</span>
              <span>{selectedProduct?.ERPGroupName || ""}</span>
            </p>
            <p>
              {" "}
              <span>Strength</span>
              <span>:</span>
              <span>{selectedProduct?.ERPStrength || ""}</span>{" "}
            </p>
            <p>
              <span>MRP</span>
              <span>:</span>{" "}
              <span>{selectedProduct?.MRP?.toFixed(2) || ""}</span>{" "}
            </p>
            <p>
              <span>Rate</span>
              <span>:</span>{" "}
              <span>
                {biilingRate === "M"
                  ? selectedProduct?.MRP?.toFixed(2)
                  : biilingRate === "S"
                  ? selectedProduct?.SaleRate?.toFixed(2)
                  : biilingRate === "T"
                  ? selectedProduct?.TradeRate?.toFixed(2)
                  : biilingRate === "D"
                  ? selectedProduct?.DistributionRate?.toFixed(2)
                  : biilingRate === "P"
                  ? selectedProduct?.PurchaseRate?.toFixed(2)
                  : biilingRate === "C"
                  ? selectedProduct?.CostRate?.toFixed(2)
                  : "Rate not available"}
              </span>{" "}
            </p>
            <p>
              {" "}
              <span>MFG Group Name</span>
              <span>:</span>
              <span>{selectedProduct?.MfgGroupName || ""}</span>{" "}
            </p>
          </div>
          <div class="modal-footer">
            {selectedProduct?.active && (
              <button
                type="button"
                className={`${styles.Btn} btn `}
                onClick={openOrderlistPopup}
              >
                Add to orderlist
              </button>
            )}
            <button
              type="button"
              className={`${styles.Btn} btn `}
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailPopup;
