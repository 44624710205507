import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import classes from "./Footer.module.css";

const Footer = () => {
  const storeId = useSelector((state) => state.store.storeId);
  const locationId = useSelector((state) => state.store.locationId);
  const navigate = useNavigate();


  const handleNavigation = (pageName, route) => {
    localStorage.setItem("PageName", pageName);
    navigate(route, { state: { id: storeId, name: pageName } });
  };

  return (
    <div className={`${classes.FooterWrapper}`}>
      <div className={`${classes.FooterLinks}`}>
        <li
         onClick={() =>
          handleNavigation(
            "Privacy Policy",
            `/${storeId}/${locationId}/companyDetails/privacyPolicy`
          )
        }
        >
          <h6> Privacy Policy</h6>
        </li>
        <li
          onClick={() =>
            handleNavigation(
              "Return Policy",
              `/${storeId}/${locationId}/companyDetails/returnPolicy`
            )
          }
        >
          <h6>Return Policy</h6>
        </li>
        <li
          onClick={() =>
            handleNavigation(
              "Shipping Policy",
              `/${storeId}/${locationId}/companyDetails/shippingPolicy`
            )
          }
        >
          <h6> Shipping Policy</h6>
        </li>
        {/* <li
          onClick={() =>
            navigate(`/${storeId}/${locationId}/companyDetails`, {
              state: { id: storeId, name: "Contact Us" },
            })
          }
        >
          <h6> Contact Us</h6>
        </li> */}
      </div>
      <div className={`${classes.FooterCopyright}`}>
        <p>2024-25 Swilmart.com. All rights reseved</p>
      </div>
    </div>
  );
};

export default Footer;
