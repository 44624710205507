import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { format, startOfMonth } from "date-fns";

import { path } from "../../Router/Routes";

import classes from "../productDetail/ProductDetail.module.css";
import styles from "../Ledger/Ledger.module.css";
import beauty from "../../reusableComponents/SideDrawer/SideDrawer.module.css";
import heights from "../customerCredit/customercredit.module.css";

import Header from "../../reusableComponents/Header";
import Loader from "../../reusableComponents/Loader/Loader";

import CustomDatePicker from "../../reusableComponents/CustomDatePicker/CustomDatePicker";

import { storeActions } from "../../Store/StoreDetails/StoreSlice";
import {
  getCustomerDebitDetails,
  getCustomerDebitList,
} from "../../Store/Customer/CustomerActions";

const CustomerDebit = () => {
  const { id, locationid } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const B2BauthToken = localStorage.getItem("B2BAuthToken");
  const [loading, setLoading] = useState(false);
  const [debitList, setDebitList] = useState([]);
  const [debitDetails, setDebitDetails] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [fromDate, setFromDate] = useState(
    format(startOfMonth(new Date()), "dd/MM/yyyy")
  );
  const [toDate, setToDate] = useState(format(new Date(), "dd/MM/yyyy"));

  useEffect(() => {
    handleDateSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!B2BauthToken) {
      toast.error("Please login first!");
      navigate(path.login);
    }
    dispatch(storeActions.setStoreIdHandler(id));
    dispatch(storeActions.setLocationIdHandler(locationid));
  }, [B2BauthToken, dispatch, id, locationid, navigate]);

  const handleDateSubmit = () => {
    if (B2BauthToken) {
      setLoading(true);
      dispatch(
        getCustomerDebitList(B2BauthToken, id, fromDate, toDate, locationid)
      ).then((result) => {
        if (result.status === "success") {
          setLoading(false);
          setDebitList(result.data);
        } else {
          setLoading(false);
        }
      });
    } else {
      toast.error("Please login first!");
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setDebitDetails("");
  };

  const handleRowSelect = (product) => {
    console.log(product);
    let data = {
      PKID: product.PKID,
      FKSeriesID: product.FKSeriesID,
    };
    dispatch(getCustomerDebitDetails(id, data)).then((result) => {
      if (result.status === "success") {
        setDebitDetails(result.data.data);
        window.open(result.data.data.Data.FileURL, "_blank");
      }
    });
  };

  return (
    <div>
      <Header pagename={"Debit Note"} />

      <div className={styles.datePickerContainer}>
        <div className="d-flex gap-4 align-center">
          <div>
            <label className={`${styles.dateText}  `} htmlFor="fromDate">
              From Date:
            </label>

            <CustomDatePicker
              selectedDate={fromDate}
              onChange={(date) => setFromDate(date)}
            />
          </div>

          <div>
            <label className={`${styles.dateText}  `} htmlFor="toDate">
              To Date:
            </label>

            <CustomDatePicker
              selectedDate={toDate}
              onChange={(date) => setToDate(date)}
            />
          </div>
        </div>
        <div className={`${styles.SubmitBtn}`}>
          <button className={`${styles.Btn}`} onClick={handleDateSubmit}>
            View
          </button>
        </div>
      </div>

      {loading === true ? (
        <div className={`${styles.PageWrapper}`}>
          <Loader />
        </div>
      ) : (
        <>
          {debitList && debitList.length ? (
            <div className={`${heights.CustomerDetailTable}`}>
              <table className={`${classes.TableComponent}`}>
                <thead className={`${classes.TableComponentHeader}`}>
                  <tr>
                    <th className={`${classes.TableName} `} scope="col">
                      ENTRY NO.
                    </th>
                    <th
                      className={`${classes.TableNum} text-start`}
                      scope="col"
                    >
                      ENTRY DATE
                    </th>
                    <th
                      className={`${classes.TableNum} text-center`}
                      scope="col"
                    >
                      REF NO.
                    </th>

                    <th
                      className={`${classes.TableNum} text-center`}
                      scope="col"
                    >
                      REF DATE
                    </th>

                    <th className={`${classes.TableNum} text-end`} scope="col">
                      GROSS AMOUNT
                    </th>
                    <th className={`${classes.TableNum} text-end`} scope="col">
                      TOTAL DISC
                    </th>
                    <th className={`${classes.TableNum} text-end`} scope="col">
                      CASH DISC
                    </th>

                    <th className={`${classes.TableNum} text-end`} scope="col">
                      TAX AMOUNT
                    </th>

                    <th className={`${classes.TableNum} text-end`} scope="col">
                      NET AMOUNT
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {debitList.map((product, index) => (
                    <tr
                      key={index}
                      className={
                        product?.DraftMode === true && product.TrnStatus === "C"
                          ? `${classes.outOfStock}`
                          : ""
                      }
                    >
                      <td
                        className={`${classes.TableName} ${styles.firstColRow} `}
                        onClick={
                          product?.DraftMode === true &&
                          product.TrnStatus === "C"
                            ? null
                            : () => handleRowSelect(product)
                        }
                      >
                        <span
                          className={`${styles.eyeIcon}  ${
                            product?.DraftMode === true &&
                            product.TrnStatus === "C"
                              ? heights.disabledIcon
                              : ""
                          }`}
                        >
                          <i class="bi bi-eye"></i>
                        </span>

                        {product.Series + " " + product.EntryNo}
                      </td>
                      <td className={`${classes.TableNum} text-start`}>
                        {new Date(product?.EntryDate)
                          .toLocaleDateString("en-GB")
                          .replace(/\//g, "-")}
                      </td>
                      <td className={`${classes.TableNum} text-center`}>
                        {product?.RefNo}
                      </td>

                      <td className={`${classes.TableNum} text-center`}>
                        {new Date(product?.GRDate)
                          .toLocaleDateString("en-GB")
                          .replace(/\//g, "-")}
                      </td>

                      <td className={`${classes.TableNum} text-end`}>
                        {product?.GrossAmt?.toFixed(2)}
                      </td>
                      <td className={`${classes.TableNum} text-end`}>
                        {(product?.TradeDisc || 0) +
                          (product?.SchemeDisc || 0) +
                          (product?.LotDisc || 0)}
                      </td>
                      <td className={`${classes.TableNum} text-end`}>
                        {product?.CashDiscAmt.toFixed(2)}
                      </td>
                      <td className={`${classes.TableNum} text-end`}>
                        {product?.TaxAmt?.toFixed(2)}
                      </td>

                      <td className={`${classes.TableNum} text-end`}>
                        {product?.NetAmt?.toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className={`${classes.NoImageContainer}`}>
              <h6>Data not found.</h6>
            </div>
          )}
        </>
      )}

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton className="p-2">
          <Modal.Title className={styles.modalTitle}>Debit Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className={`${beauty.CreditContent} modal-body-scrollable`}>
          {debitDetails &&
          debitDetails.TransDetail &&
          debitDetails.TransDetail.length > 0 ? (
            <>
              {debitDetails.TransDetail.map((detail, index) => {
                if (index % 2 === 0) {
                  return (
                    <div key={index} className={`${beauty.CreditRow}`}>
                      <div className={`${beauty.CreditSummaryBox}`}>
                        <div
                          className={`${beauty.Credit} ${beauty.productCont} mt-2`}
                        >
                          <h6>{detail?.NameToDisplay}</h6>
                          <p>
                            Batch:{" "}
                            <span className="fw-bold">{detail?.Batch}</span>
                          </p>
                          <p>
                            Qty: <span className="fw-bold">{detail?.Qty}</span>
                          </p>
                          <p>
                            Rate:{" "}
                            <span className="fw-bold">
                              {detail?.ProdCostRate}
                            </span>
                          </p>
                          <div className={`${classes.ProductPrice}`}>
                            <span className={`${classes.Amount}`}>
                              {detail?.ProdMRP.toFixed(2)}
                            </span>
                            <span className={`${classes.Rupee} ms-1`}>₹</span>
                          </div>
                        </div>
                      </div>
                      {index + 1 < debitDetails.TransDetail.length && (
                        <div className={`${beauty.CreditSummaryBox}`}>
                          <div
                            className={`${beauty.Credit} ${beauty.productCont} mt-2`}
                          >
                            <h6>
                              {
                                debitDetails.TransDetail[index + 1]
                                  ?.NameToDisplay
                              }
                            </h6>
                            <p>
                              Batch:{" "}
                              <span className="fw-bold">
                                {debitDetails.TransDetail[index + 1]?.Batch}
                              </span>
                            </p>
                            <p>
                              Qty:{" "}
                              <span className="fw-bold">
                                {debitDetails.TransDetail[index + 1]?.Qty}
                              </span>
                            </p>
                            <p>
                              Rate:{" "}
                              <span className="fw-bold">
                                {
                                  debitDetails.TransDetail[index + 1]
                                    ?.ProdCostRate
                                }
                              </span>
                            </p>
                            <div className={`${classes.ProductPrice}`}>
                              <span className={`${classes.Amount}`}>
                                {debitDetails.TransDetail[
                                  index + 1
                                ]?.ProdMRP.toFixed(2)}
                              </span>
                              <span className={`${classes.Rupee} ms-1`}>₹</span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                }
                return null;
              })}
            </>
          ) : (
            <p>No transaction details available.</p>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CustomerDebit;
