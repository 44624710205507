import React from "react";
import Select from "react-select";

import "./Input.css";

const DropdownInput = (props) => {
  const { setCategoryHandler, categoryList, value, optionName, name, page } = props;
  const options = categoryList.map((category) => ({
    value: category.value,
    label: category.label,
  }));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: "14px",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "14px",
    }),
  };

  return (
    <div className={page === 'payment' ? 'customdrop' :  'selectClass' }>
      <Select
        value={options.find((option) => option.value === value) ?? ""}
        options={options}
        placeholder={`Select ${optionName}`}
        onChange={(selectedOption) =>
          setCategoryHandler({
            target: { name: name, value: selectedOption?.value },
          })
        }
        isSearchable={false}
        styles={customStyles}
      />
    </div>
  );
};

export default DropdownInput;