import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { format, startOfMonth } from "date-fns";
import Loader from "../../reusableComponents/Loader/Loader";
import CustomDatePicker from "../../reusableComponents/CustomDatePicker/CustomDatePicker";
import Header from "../../reusableComponents/Header";

import heights from "../customerCredit/customercredit.module.css";
import classes from "../productDetail/ProductDetail.module.css";
import styles from "../Ledger/Ledger.module.css";

import { path } from "../../Router/Routes";
import { storeActions } from "../../Store/StoreDetails/StoreSlice";
import {
  getCustomerInvoice,
  getInvoiceCSV,
  getInvoicePdf,
} from "../../Store/Customer/CustomerActions";

const InvoiceReport = () => {
  const { id, locationid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const B2BauthToken = localStorage.getItem("B2BAuthToken");
  const [fromDate, setFromDate] = useState(format(startOfMonth(new Date()), "dd/MM/yyyy"));
  const [toDate, setToDate] = useState(format(new Date(), "dd/MM/yyyy"));
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState([]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const [status, setStatus] = useState("All")

  useEffect(() => {
    handleDateSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!B2BauthToken) {
      toast.error("Please login first!");
      navigate(path.login);
    }
    dispatch(storeActions.setStoreIdHandler(id));
    dispatch(storeActions.setLocationIdHandler(locationid));
  }, [B2BauthToken, dispatch, id, locationid, navigate]);


  // const handleStatusFilter = (e) => {
  //   setStatus(e.target.value);
 
  // };

  const handleDateSubmit = () => {
    if (B2BauthToken) {
      if (fromDate != null && toDate != null) {
        setLoading(true);
        dispatch(
          getCustomerInvoice(B2BauthToken, id, fromDate, toDate, locationid, "All")
        ).then((result) => {
          if (result.status === "success") {
            setReport(result.data);
          } 
          // else if (result.status === "error") {
          //   toast.error("Something is Wrong, Please try again later.", {
          //     autoClose: false,
          //   });
          // }
          setLoading(false);
        });
      } else {
        toast.error("Please select the dates");
      }
    } else {
      toast.error("Please Login First!");
    }
  };

  const getInvoiceDAta = (invoice) => {
    const data = {
      FKSeriesID: invoice.FKSeriesID,
      ID: invoice.PKID,
    };
    dispatch(getInvoicePdf(id, data))
      .then((result) => {
        if (result.status === "success" && result.data.data) {
          window.open(result.data.data.FileURL, "_blank");
        }
        //  else if (result.status === "error") {
        //   toast.error("Something is Wrong, Please try again later.", {
        //     autoClose: false,
        //   });
        
        // } 
        else {
          toast.error("Data not found! Please try again later.");
        }
      })
      .catch((error) => {
        toast.error("An error occurred while fetching the invoice data.");
      });
  };

  const downloadInvoiceCSVFile = (invoice) => {
    const data = {
      FKSeriesID: invoice.FKSeriesID,
      ID: invoice.PKID,
    };
    dispatch(getInvoiceCSV(id, data))
      .then((result) => {
        if (result.status === "success") {
          window.open(result.data, "_blank");
        } 
         else {
          toast.error("Data not found! Please try again later.");
        }
      })
      .catch((error) => {
        toast.error("An error occurred while fetching the invoice data.");
      });
  };



  return (
    <div>
      <Header pagename={"Invoice Details"} />

      <div className={styles.datePickerContainer}>
        <div className="d-flex gap-4">
        <div className="d-flex gap-4 align-center">
          <div>
            <label className={`${styles.dateText}  `} htmlFor="fromDate">
              From Date:
            </label>

            <CustomDatePicker
              selectedDate={fromDate}
              onChange={(date) => setFromDate(date)}
            />
          </div>

          <div>
            <label className={`${styles.dateText}  `} htmlFor="toDate">
              To Date:
            </label>

            <CustomDatePicker
              selectedDate={toDate}
              onChange={(date) => setToDate(date)}
            />
          </div>
        </div>
      
              {/* <div
                className={`status-filter ms-2 `}
              >
                <select className="form-select" onChange={handleStatusFilter}>
                  <option value="All">All Status</option>
                  <option value="Draft">Drafted</option>
                  <option value="Cancelled">Cancelled</option>
                  
                </select>
              </div> */}
              </div>
        <div className={`${styles.SubmitBtn}`}>
          <button className={`${styles.Btn}`} onClick={handleDateSubmit}>
            View
          </button>
        </div>
      </div>

      {loading === true ? (
        <div className={`${styles.PageWrapper}`}>
          <Loader />
        </div>
      ) : (
        <>
          {report && report.length ? (
            <div className={`${styles.LedgerDetailTable}`}>
              <table className={`${classes.TableComponent}`}>
                <thead className={`${classes.TableComponentHeader}`}>
                  <tr>
                    <th className={`${classes.TableName} `} scope="col">
                      ENTRY NO.
                    </th>
                    <th
                      className={`${classes.TableNum} text-start`}
                      scope="col"
                    >
                      ENTRY DATE
                    </th>

                    <th className={`${classes.TableNum} text-end`} scope="col">
                      REF NO.
                    </th>

                    <th
                      className={`${classes.TableNum} text-center`}
                      scope="col"
                    >
                      REF DATE
                    </th>
                  
                    <th className={`${classes.TableNum} text-end`} scope="col">
                      GROSS AMT
                    </th>
                    <th className={`${classes.TableNum} text-end`} scope="col">
                      TOTAL DISC
                    </th>
                    <th className={`${classes.TableNum} text-end`} scope="col">
                      CASH DISC
                    </th>
                    <th className={`${classes.TableNum} text-end`} scope="col">
                      TAX AMOUNT
                    </th>
                    <th className={`${classes.TableNum} text-end`} scope="col">
                      NET AMOUNT
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {report.map((product, index) => (
                    <tr key={index}   
                    
                    className={
                      product?.DraftMode === true && product.TrnStatus === "C"
                        ? `${classes.outOfStock}`
                        : ""
                    }
                    
                    >
                      <td
                        className={`${classes.TableName} ${styles.firstColRow}   ${
                            product?.DraftMode === true &&
                            product.TrnStatus === "C"
                              ? heights.disabledIcon
                              : ""
                          }  `}
                      >
                        <div 
                        
                        // data-bs-toggle="dropdown" 
                        data-bs-toggle={product?.DraftMode === true && product.TrnStatus === "C" ? "" : "dropdown"}
                        
                        
                        aria-expanded="false">
                          <span className={`${styles.eyeIcon}`}>
                            <i className="bi bi-download"></i>
                          </span>
                          <div className="dropdown">
                            <ul
                              className={`${styles.dropdownlistchoice} dropdown-menu`}
                            >
                              <li
                                className={`${styles.checkListPDF} ${styles.checkLis}`}
                                onClick={() => getInvoiceDAta(product)}
                              >
                                PDF
                              </li>
                              <li
                                className={`${styles.checkListPDF}`}
                                onClick={() => downloadInvoiceCSVFile(product)}
                              >
                                CSV
                              </li>
                            </ul>
                          </div>
                        </div>

                        {product.Series + product.EntryNo}
                      </td>
                      <td className={`${classes.TableNum} text-start`}>
                        {new Date(product.EntryDate)
                          .toLocaleDateString("en-GB")
                          .replace(/\//g, "-")}
                      </td>

                      <td className={`${classes.TableNum} text-end`}>
                        {product.Grno}
                      </td>
                      <td className={`${classes.TableNum} text-center`}>
                        {new Date(product?.DeliveryDate)
                          .toLocaleDateString("en-GB")
                          .replace(/\//g, "-")}
                      </td>
                     
                      <td className={`${classes.TableNum} text-end`}>
                        {product?.GrossAmt?.toFixed(2)}
                      </td>
                      <td className={`${classes.TableNum} text-end`}>
                        {" "}
                        {(
                          (product?.TradeDisc || 0) +
                          (product?.SchemeDisc || 0) +
                          (product?.LotDisc || 0)
                        ).toFixed(2)}
                      </td>
                      <td className={`${classes.TableNum} text-end`}>
                        {product?.CashDiscAmt?.toFixed(2)}
                      </td>
                      <td className={`${classes.TableNum} text-end`}>
                        {product?.TaxAmt?.toFixed(2)}
                      </td>
                      <td className={`${classes.TableNum} text-end`}>
                        {product?.NetAmt?.toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className={`${classes.NoImageContainer}`}>
              <h6>Data not found.</h6>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default InvoiceReport;
