import React from "react";
import classes from "./Header.module.css";

const Header = ({ pagename, BtnName, Amount, onClick })  => {

  return (
    <div className={classes.HeadingSection}>
      <h2>{pagename}</h2>
      <div className={classes.headingBtn}>
        {pagename === "Create Order" ? <h3>Total Amount: {Amount.toFixed(2)}</h3> : ""}
        {pagename === "Invoice Details" || pagename==="Credit Note" || pagename==="Debit Note" ? <p className={`${classes.Cancelled}`}><span className={`${classes.CancelledCircle}`}></span>Cancelled</p> : ""}

        {BtnName && (
          <button className={classes.Btn} onClick={onClick}>
            {BtnName}
          </button>
        )}
      </div>
    </div>
  );
};

export default Header;
