import React from 'react';
import DatePicker from 'react-datepicker';
import { format, isValid } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import classes from "./CustomDatePicker.module.css"

const CustomDatePicker = ({ selectedDate, onChange }) => {
  const handleDateChange = (date) => {
    if (date && isValid(date)) {
      const formattedDate = format(date, 'dd/MM/yyyy');
      onChange(formattedDate);
    } else {
      onChange(''); 
    }
  };

  const parseDate = (dateInput) => {
    if (!dateInput) return null;
    if (dateInput instanceof Date) return dateInput;
    if (typeof dateInput === 'string') {
      const [day, month, year] = dateInput.split('/');
      return new Date(year, month - 1, day);
    }
    return null;
  };
  const handleKeyDown = (event) => {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '/'];
    if (!allowedKeys.includes(event.key) && event.key !== 'Backspace' && event.key !== 'Delete' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
      event.preventDefault();
    }
  };

  return (
    <DatePicker
    showIcon
      selected={parseDate(selectedDate)}
      onChange={handleDateChange}
      dateFormat="dd/MM/yyyy"
      className={classes.customdatepicker}
      placeholderText="dd/mm/yyyy"
      onKeyDown={handleKeyDown}
    />
  );
};

export default CustomDatePicker;
