import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styles from "../productDetail/ProductDetail.module.css";
import classes from "./Order.module.css";
import NoOrder from "../../Assessts/Images/NoOrder.png";
import {
  UpdateOrderListHandler,
  DeleteOrderListItemHandler,
  OrderListHandler,
} from "../../Store/OrderList/OrderListActions";
// import { toast } from "react-toastify";

const OrderList = ({ orderlist, setOrderlist, id, locationid }) => {
  const dispatch = useDispatch();
  const B2BauthToken = localStorage.getItem("B2BAuthToken");
  const [delAddress, setDelAddress] = useState("");

  const handleUpdateQuantity = async (index, newQuantity) => {


    let updatedQuantity = parseInt(newQuantity);

    
    // Ensure the quantity doesn't go below 1
    if (updatedQuantity < 1 || isNaN(updatedQuantity)) {
      updatedQuantity = 1;  // Reset to 1 if below 1 or if input is invalid (e.g., empty)
    }

    

    const updatedOrderList = [...orderlist];
    updatedOrderList[index].ProdutQty = updatedQuantity;
    setOrderlist(updatedOrderList);
 
    const order = {
      StoreId: id,
      ProductPKID: updatedOrderList[index].ProductPKID,
      ProdutQty: updatedQuantity,
      Remarks: updatedOrderList[index].Remark,
    };

    dispatch(UpdateOrderListHandler(B2BauthToken, order));
  };

  const handleUpdateRemark = async (index, newRemark) => {
    const updatedOrderList = [...orderlist];
    updatedOrderList[index].Remark = newRemark;
    setOrderlist(updatedOrderList);

    const order = {
      StoreId: id,
      ProductPKID: updatedOrderList[index].ProductPKID,
      ProdutQty: updatedOrderList[index].ProdutQty,
      Remarks: newRemark,
    };

    dispatch(UpdateOrderListHandler(B2BauthToken, order));
  
  };

  const handleItemDelete = async (productid) => {
    if (B2BauthToken) {
      dispatch(DeleteOrderListItemHandler(B2BauthToken, id, productid)).then(
        (result) => {
          if (result.status === "success") {
            dispatch(OrderListHandler(B2BauthToken, id, locationid)).then(
              (result) => {
                if (result.status === "success") {
                  setOrderlist(result.data.lineItems);
                }
              }
            );
          }
          
        }
      );
    }
  };

  return (
    <>
      <div className={`${classes.OrderListHeading}`}>
        <h1>Cart</h1>
      </div>
      {orderlist && orderlist.length ? (
        <div
          className={`${styles.ProductDetailTable} ${classes.ProductDetailTable}`}
        >
          <table className={`${styles.TableComponent}`}>
            <thead className={`${styles.TableComponentHeader}`}>
              <tr>
                <th className={`${styles.TableName}`} scope="col">
                  PRODUCT NAME
                </th>

                <th className={`${styles.TableName}`} scope="col">
                  CATEGORY
                </th>
                <th className={`${styles.TableName}`} scope="col">
                  GROUP
                </th>
                {/* <th className={`${styles.TableNum}`} scope="col">
                  STOCK
                </th> */}

                <th className={`${styles.TableNum}`} scope="col">
                  RATE
                </th>
                {/* <th className={`${styles.TableNum}`} scope="col">
                  BILLING RATE
                </th> */}
                <th className={`${styles.TableNum}`} scope="col">
                  QUANTITY
                </th>

                <th className={`${styles.TableNum}`} scope="col">
                  AMOUNT
                </th>
                <th className={`${styles.TableName}`} scope="col">
                  REMARK
                </th>

                <th className={`${styles.TableNum}`} scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {orderlist.map((product, index) => (
                <tr key={index}>
                  <td className={`${styles.TableName}`}>
                    {product.ProductName}
                  </td>

                  <td className={`${styles.TableName}`}>{product.Category}</td>
                  <td className={`${styles.TableName}`}>
                    {product.ManufacturerGroup}
                  </td>
                  {/* <td className={`${styles.TableNum}`}>{product.stock}</td> */}
                  {/* <td className={`${styles.TableNum}`}>
                    {product.ProductPrice.toFixed(2)}
                  </td> */}

                  <td className={`${styles.TableNum}`}>
                    {product?.BillingRate?.toFixed(2)}
                  </td>
                  <td className={`${styles.TableNum} ${classes.quantityInput}`}>
                    {" "}
                    <input
                      type="number"
                      value={product.ProdutQty}
                      min="1"
                      onChange={(e) =>
                        handleUpdateQuantity(index, e.target.value)
                      }
                    />
                  </td>
                  <td className={`${styles.TableNum}`}>
                    {(product.BillingRate * product.ProdutQty).toFixed(2)}
                  </td>
                  <td className={`${styles.TableName}`}>
                    <textarea
                      value={product.Remark}
                      className={`${classes.RemarkBox} p-2`}
                      onChange={(e) =>
                        handleUpdateRemark(index, e.target.value)
                      }
                      placeholder="Write your remak here"
                    ></textarea>
                  </td>

                  <td
                    className={`${styles.TableNum} cursor-pointer`}
                    data-bs-target="#exampleModal"
                    data-bs-toggle="modal"
                    onClick={() => setDelAddress(product.ProductId)}
                  >
                    <i className="bi bi-trash3-fill cursor-pointer"></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className={`${styles.NoImageContainer}`}>
          <img src={NoOrder} alt="no-products-found" />
        </div>
      )}

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className={`${classes.addressConfirmPopup} modal-dialog modal-dialog-centered`}
        >
          <div className="modal-content" style={{ borderRadius: "0px" }}>
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Confirmation for Product Removal
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className={`${classes.listConfirmMsg} modal-body`}>
              Are you sure you want to delete this product?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className={`${classes.Btn} btn ConModal`}
                onClick={() => handleItemDelete(delAddress)}
                data-bs-dismiss="modal"
              >
                Confirm
              </button>
              <button
                type="button"
                className="btn btn-secondary CanModal"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderList;
