import React, { useEffect, useRef, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import PaginationBox from "../../reusableComponents/Pagination/PaginationBox";
import DropdownInput from "../../reusableComponents/Input/DropDownInput";
import SelectInput from "../../reusableComponents/Input/SelectInput";
import Loader from "../../reusableComponents/Loader/Loader";
import Header from "../../reusableComponents/Header";

import {
  getProductByStoreHandler,
  getStockDetails,
} from "../../Store/Product/ProductActions";
import { getSeriesInfoLocationwise } from "../../Store/StoreDetails/StoreActions.js";
import { AddOrderListHandler } from "../../Store/OrderList/OrderListActions";
import { storeActions } from "../../Store/StoreDetails/StoreSlice";
import { productActions } from "../../Store/Product/ProductSlice";
import { path } from "../../Router/Routes.js";

import NoProducts from "../../Assessts/Images/NoProducs.png";

import classes from "./ProductDetail.module.css";
import styles from "../order/Order.module.css";
import OrderlistPopup from "./OrderlistPopup.js";
import ProductDetailPopup from "./ProductDetailPopup.js";

const ProductDetail = () => {
  const quantityInputRef = useRef(null);
  const productModalRef = useRef(null);

  const { id, locationid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [groupNameFilter, setGroupNameFilter] = useState("all");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [inactiveProducts, setInactiveProducts] = useState(0);
  let [uniqueGroupNames, setUniqueGroupNames] = useState([]);
  const B2BauthToken = localStorage.getItem("B2BAuthToken");
  const [statusFilter, setStatusFilter] = useState("true");
  const [totalPageItems, setTotalPageItems] = useState(0);
  const [total_Items, setTotal_Items] = useState(0);
  const [activeProducts, setActiveProducts] = useState(0);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [totalProducts, setTotalProducts] = useState(0);
  const [biilingRate, setBillingRate] = useState(null);
  let [stockDetails, setStockDetails] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  // eslint-disable-next-line
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [remark, setRemark] = useState("");
  const limit = 10;

  useEffect(() => {
    if (!B2BauthToken) {
      toast.error("Please login first!");
      navigate(path.login);
    }
    dispatch(storeActions.setStoreIdHandler(id));
    dispatch(storeActions.setLocationIdHandler(locationid));
    if (locationid) {
      dispatch(getSeriesInfoLocationwise(id, locationid)).then((result) => {
        if (result.status === "success") {
          setBillingRate(result.data.BillingRate);
        }
      });
    }
  }, [B2BauthToken, dispatch, id, locationid, navigate]);

  useEffect(() => {
    setLoading(true);
    const params = {
      ClientRegID: id,
      LocationID: locationid,
      page: currentPage,
      limit: limit,
      active: statusFilter,
      search: searchQuery,
      MfgGroupName: groupNameFilter,
    };

    const showStockDetails = () => {
      dispatch(getStockDetails(id, locationid)).then((result) => {
        setStockDetails(result.data);
      });
    };

    const debounceSearch = setTimeout(async () => {
      dispatch(getProductByStoreHandler(params)).then((result) => {
        if (result.status === "success") {
          if (searchQuery.length <= 1) {
            setProducts(result.data);
            dispatch(productActions.setProductListHandler(result.data));
          }
          setProducts(result.data);
          dispatch(productActions.setProductListHandler(result.data));
          setLoading(false);
          setUniqueGroupNames(result.uniqueGroupNames);
          setTotalProducts(result.totalItems);
          setTotal_Items(result.total_Items);
          setInactiveProducts(result.totalInActiveItems);
          setTotalPages(result.totalPages);
          setTotalItems(result.totalItems);
          setActiveProducts(result.totalActiveItems);
          setTotalPageItems(result.resultProd);
          showStockDetails();
        } else {
          setLoading(false);
          toast.error("An error occured. Please refresh your page.");
        }
      });
    }, [1000]);

    return () => clearTimeout(debounceSearch);
  }, [
    dispatch,
    id,
    locationid,
    currentPage,
    limit,
    statusFilter,
    searchQuery,
    groupNameFilter,
  ]);

  useEffect(() => {
    if (showModal && quantityInputRef.current) {
      quantityInputRef.current.focus();
      quantityInputRef.current.select();
    }
  }, [showModal]);

  const handleSearch = (e) => {
    const newSearchQuery = e.target.value;
    setCurrentPage(1);
    setSearchQuery(newSearchQuery);
    setSelectedIndex(-1);
    if (newSearchQuery.length > 0) {
      setIsDropdownOpen(true);
    } else {
      setSearchResults([]);
    }
  };

  const handleKeyDown = (e) => {
    if (!isDropdownOpen) return;

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setSelectedIndex((prevIndex) =>
          prevIndex < searchResults.length - 1 ? prevIndex + 1 : prevIndex
        );
        break;
      case "ArrowUp":
        e.preventDefault();
        setSelectedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : -1));
        break;
      case "Enter":
        e.preventDefault();
        if (selectedIndex >= 0 && selectedIndex < searchResults.length) {
          handleDropdownItemClick(searchResults[selectedIndex]);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (searchResults.length > 0) {
      setIsDropdownOpen(true);
    } else {
      setIsDropdownOpen(false);
    }
  }, [searchResults]);

  const handleDropdownItemClick = (item) => {
    setSearchQuery(item.ERPProductName);
    setIsDropdownOpen(false);
  };

  const handleStatusFilter = (e) => {
    setStatusFilter(e.target.value);
    setCurrentPage(1);
  };

  const handleGroupNameFilter = (e) => {
    setGroupNameFilter(e.target.value);
    setCurrentPage(1);
  };

  const getStockForProduct = (productPKID) => {
    const stockArray = stockDetails.filter(
      (stock) => stock.product_PKID === productPKID.toString()
    );

    if (stockArray.length > 0) {
      return stockArray[0]?.totalStock;
    } else {
      return 0;
    }
  };

  function formatDateTime(dateTimeString) {
    const date = new Date(dateTimeString);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const hours12 = hours % 12 || 12;

    return `${day} ${month} ${year} ${hours12}:${padZero(minutes)} ${ampm}`;
  }

  function padZero(num) {
    return (num < 10 ? "0" : "") + num;
  }

  const filteredProducts = products.filter((product) => {
    const groupNameFilterMatch =
      groupNameFilter === "all" ||
      (groupNameFilter === "Not Listed" && !product?.MfgGroupName) ||
      product?.MfgGroupName === groupNameFilter;

    const searchMatch = product.ERPProductName.toLowerCase().includes(
      searchQuery.toLowerCase()
    );

    return groupNameFilterMatch && (searchQuery.length <= 1 || searchMatch);
  });

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedProduct(null);
    setQuantity(1);
    setRemark("");
  };

  const openOrderlistPopup = () => {
    setShowModal(true);
    const productModal = productModalRef.current;
    if (productModal) {
      const bsModal = window.bootstrap.Modal.getInstance(productModal);
      if (bsModal) {
        bsModal.hide();
      }
    }
  };

  const handleQuantityChange = (e) => {
    const value = e.target.value;

    if (/^\d*$/.test(value)) {
      setQuantity(Number(value));
    }
  };

  const handleRemarkChange = (e) => {
    setRemark(e.target.value);
  };

  const addToOrderList = (product) => {
    if (B2BauthToken) {
      const newProduct = {
        ProductId: product._id,
        ProductPKID: product.PKID,
        ProductPrice: product.SaleRate,
        ProdutQty: quantity,
        Ptr: product.Ptr,
        Remarks: remark,
        LocationId: locationid,
      };
      const orderdetail = {
        CustomerId: id,
        StoreId: id,
        lineItems: [newProduct],
      };

      dispatch(AddOrderListHandler(B2BauthToken, orderdetail)).then(
        (result) => {
          if (result.status === "success") {
            toast.success("Product added successfully!");
          }
        }
      );
    } else {
      alert("Please login first!");
    }
  };

  const handleAddToOrderList = (e) => {
    e.preventDefault();
    if (quantity === 0) {
      toast.error("Quantity cannot be less than one");
      return;
    }
    e.preventDefault();

    addToOrderList(selectedProduct);
    handleCloseModal();
  };

  const setProductDetails = (e, product) => {
    e.preventDefault();

    setTimeout(() => {
      setSelectedProduct(product);
    }, 0);
  };

  return (
    <>
      <Header pagename={"Product List"} />
      <div className={`${classes.ProductDetailWrapper}`}>
        <div className={`${classes.ProductDetailTop}`}>
          <div className={`${classes.ProductTopCards}`}>
            <div className={`${classes.ProductTopComponent}`}>
              <h2>{totalProducts}</h2>
              <p>Total Products</p>
            </div>
            <div className={`${classes.ProductTopComponent}`}>
              <h2>{inactiveProducts}</h2>
              <p>Inactive Products</p>
            </div>
            <div className={`${classes.ProductTopComponent}`}>
              <h2>{activeProducts}</h2>
              <p>Active Products</p>
            </div>
            {stockDetails && (
              <div className={`${classes.ProductTopComponent}`}>
                <h2>
                  {stockDetails[0]?.updatedAt
                    ? formatDateTime(stockDetails[0].updatedAt)
                    : ""}
                </h2>
                <p>Last Stock Updated Date</p>
              </div>
            )}
          </div>
        </div>

        <div className={`${classes.ProductDetailSearch}`}>
          <div className={`${classes.SearchProduct}`}>
            <input
              type="search"
              className={`${classes.ProductSearchBar} form-control`}
              aria-label="Search"
              placeholder="Search Product"
              onChange={handleSearch}
              onKeyDown={handleKeyDown}
              value={searchQuery}
            />
            <span className={`${classes.SearchIcon}`}>
              <i className="bi bi-search"></i>
            </span>
          </div>

          <DropdownInput
            value={statusFilter}
            name={"Status"}
            optionName={"Status"}
            setCategoryHandler={handleStatusFilter}
            categoryList={[
              { value: "", label: "All" },
              { value: "true", label: "Active Products 🟢" },
              { value: "false", label: "Inactive Products 🔴" },
            ]}
          />
          <SelectInput
            value={groupNameFilter}
            name={"Manufacture"}
            optionName={"Manufacturer"}
            setCategoryHandler={handleGroupNameFilter}
            categoryList={[...uniqueGroupNames, "Not Listed"]}
          />
        </div>

        <div className={`${classes.ProductDetailTable}`}>
          <table className={`${classes.TableComponent}`}>
            <thead className={`${classes.TableComponentHeader}`}>
              <tr>
                <th className={`${classes.TableName}`} scope="col">
                  PRODUCT NAME
                </th>
                <th className={`${classes.TableName}`} scope="col">
                  BRAND
                </th>
                <th className={`${classes.TableNum}`} scope="col">
                  CATEGORY
                </th>
                <th className={`${classes.TableNum}`} scope="col">
                  STATUS
                </th>

                <th className={`${classes.TableNum} text-center`} scope="col">
                  STOCK
                </th>
                <th className={`${classes.TableNum} text-end`} scope="col">
                  RATE
                </th>
                <th className={`${classes.TableNum} text-end`} scope="col">
                  MRP
                </th>
                <th className={`${classes.TableNum} text-end`} scope="col">
                  SCHEME
                </th>
                <th className={`${classes.TableName}`} scope="col">
                  MANUFACTURER
                </th>
                <th className={`${classes.TableNum}`} scope="col">
                  STRENGTH
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <div className={`${classes.PageWrapper}`}>
                  <Loader />
                </div>
              ) : (
                <>
                  {filteredProducts.length > 0 ? (
                    filteredProducts.map((product, index) => (
                      <tr
                        key={index}
                        onClick={(e) => setProductDetails(e, product)}
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                        className={`${
                          getStockForProduct(product.PKID) === 0
                            ? classes.outOfStock
                            : ""
                        } ${classes.ProductRowDetails}`}
                      >
                        <td className={`${classes.TableName}`}>
                          {product.ERPProductName}
                        </td>
                        <td className={`${classes.TableName}`}>
                          {product.ERPBrand}
                        </td>
                        <td className={`${classes.TableNum}`}>
                          {product.ERPCategory}
                        </td>
                        <td className={`${classes.TableNum}`}>
                          <p
                            className={`${styles.status} ${
                              product.active
                                ? `${styles.DeliveredOrder}`
                                : `${styles.CancelledOrder}`
                            }`}
                          >
                            {product.active ? "Active" : "Inactive"}
                          </p>
                        </td>

                        <td className={`${classes.TableNum} text-center`}>
                          {getStockForProduct(product.PKID).toFixed(2)}
                        </td>
                        <td className={`${classes.TableNum} text-end`}>
                          {biilingRate === "M"
                            ? product?.MRP?.toFixed(2)
                            : biilingRate === "S"
                            ? product?.SaleRate?.toFixed(2)
                            : biilingRate === "T"
                            ? product?.TradeRate?.toFixed(2)
                            : biilingRate === "D"
                            ? product?.DistributionRate?.toFixed(2)
                            : biilingRate === "P"
                            ? product?.PurchaseRate?.toFixed(2)
                            : biilingRate === "C"
                            ? product?.CostRate?.toFixed(2)
                            : "Rate not available"}
                        </td>

                        <td className={`${classes.TableNum} text-end`}>
                          {product?.MRP?.toFixed(2)}
                        </td>

                        <td className={`${classes.TableNum}`}>{}</td>
                        <td className={`${classes.TableName}`}>
                          {product.MfgGroupName}
                        </td>
                        <td className={`${classes.TableNum}`}>
                          {product.Strength}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <div className={`${classes.NoImageContainer}`}>
                        <img src={NoProducts} alt="no-products-found" />
                      </div>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>

        <PaginationBox
          limit={limit}
          totalItems={totalPageItems}
          currentPage={currentPage}
          totalPages={totalPages}
          totalProducts={total_Items}
          onPageChange={(page) => setCurrentPage(page)}
          total_Items={total_Items}
        />
      </div>

      <ProductDetailPopup
        productModalRef={productModalRef}
        selectedProduct={selectedProduct}
        biilingRate={biilingRate}
        openOrderlistPopup={openOrderlistPopup}
      />

      <OrderlistPopup
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        selectedProduct={selectedProduct}
        quantityInputRef={quantityInputRef}
        quantity={quantity}
        handleQuantityChange={handleQuantityChange}
        biilingRate={biilingRate}
        remark={remark}
        handleRemarkChange={handleRemarkChange}
        handleAddToOrderList={handleAddToOrderList}
      />
    </>
  );
};

export default ProductDetail;
