export const path = {
    login: `/`,
    storeList: `/store-list`,
    productDetail: `/:id/:locationid/product`,
    createOrder: `/:id/:locationid/order`,
    orderHistory: `/:id/:locationid/orderHistory`,
    orderSucess: `/:id/:locationid/orderSuccess`,
    companyDetails: `/:id/:locationid/companyDetails/:details`,
    ledger: `/:id/:locationid/ledger`,
    outstanding: `/:id/:locationid/outstanding`,
    inProgress: `/:id/:locationid/inprogress`,
    invoiceReport: `/:id/:locationid/invoiceReport`,
    profileCustomer: `/:id/:locationid/customerProfile`,
    creditList: `/:id/:locationid/customerCreditList`,
    debitList: `/:id/:locationid/customerDebitList`,
    payment: `/:id/:locationid/payments`,
    notFound : `/notfound`



};