// import React from "react";
// import classes from "./PaginationBox.module.css";
// // import ReactPaginate from "react-paginate";
// import { ReactComponent as ArrowRightSvg } from "../../Assessts/SvgComponents/arrowRightSvg.svg";
// import { ReactComponent as ArrowLeft } from "../../Assessts/SvgComponents/ArrowLeft.svg";

// function PaginationBox({
//   limit,
//   totalItems,
//   currentPage,
//   totalProducts,
//   totalPages,
//   onPageChange,
//   total_Items,
// }) {
//   const handlePageChange = (page) => {
//     onPageChange(page);
//   };

//   const startItem = (currentPage - 1) * limit + 1;
//   const endItem = Math.min(currentPage * limit, total_Items );
// console.log("totalItmes---", totalItems)
//   console.log("total_Items-----", total_Items);
//   console.log("totalPages-----", totalPages);
//   console.log("totalProducts----", totalProducts);

//   return (
//     <div className={classes.paginationContent}>
//       <div className={classes.paginationInfo}>
//         Showing {startItem} to {endItem} of {totalProducts}{" "}
//         results.
//       </div>
//       <div className={classes.paginationControls}>
//         <button
//           className={`${classes.prevButton} ${
//             currentPage === 1 ? classes.opacSvg : ""
//           }`}
//           onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
//         >
//           <ArrowLeft />
//         </button>
//         <span className={classes.currentPage}>{currentPage}</span>

//         <button
//           className={`${classes.nextButton} ${
//             currentPage === totalPages ? classes.opacSvg : ""
//           }`}
//           onClick={() =>
//             currentPage < totalPages &&
//             endItem>= total_Items &&
//             handlePageChange(currentPage + 1)
//           }
//         >
//           <ArrowRightSvg />
//         </button>
//       </div>
//     </div>
//   );
// }

// export default PaginationBox;

import React from "react";
import classes from "./PaginationBox.module.css";
import { ReactComponent as ArrowRightSvg } from "../../Assessts/SvgComponents/arrowRightSvg.svg";
import { ReactComponent as ArrowLeft } from "../../Assessts/SvgComponents/ArrowLeft.svg";

function PaginationBox({
  limit,
  totalItems,
  currentPage,
  totalProducts,
  totalPages,
  onPageChange,
  total_Items,
}) {
  const handlePageChange = (page) => {
    onPageChange(page);
  };

  const startItem = (currentPage - 1) * limit + 1;
  const endItem = Math.min(currentPage * limit, total_Items);


  return (
    <div className={classes.paginationContent}>
      <div className={classes.paginationInfo}>
        Showing {startItem} to {endItem} of {totalProducts} results.
      </div>
      <div className={classes.paginationControls}>
        <button
          className={`${classes.prevButton} ${
            currentPage === 1 ? classes.opacSvg : ""
          }`}
          onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
        >
          <ArrowLeft />
        </button>
        <span className={classes.currentPage}>{currentPage}</span>

        <button
          className={`${classes.nextButton} ${
            currentPage === totalPages ? classes.opacSvg : ""
          }`}
          onClick={() => {
            if (currentPage < totalPages && endItem < total_Items) {
              handlePageChange(currentPage + 1);
            }
          }}
          disabled={endItem >= total_Items} // Disable if endItem is greater than or equal to total_Items
        >
          <ArrowRightSvg />
        </button>
      </div>
    </div>
  );
}

export default PaginationBox;